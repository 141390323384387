import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const RealKredit = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Was ist eigentlich ein Realkredit?" showCalc={false} />
            <Article>
                <p>
                    Nein, ein Realkredit ist nicht realer als andere Kredite. Realkredite sind nichts anderes als eine
                    andere Bezeichnung von Hypothekarkrediten. Das sind Kredite, bei denen die Bank das Darlehen mit
                    Sicherheiten wie Grundrechten oder Hypotheken absichert. Der Name bezieht sich lediglich auf die Art
                    der Besicherung durch die Bank. Was du darüber wissen solltest, erfährst du in diesem kurzen
                    Beitrag.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Was du über Realkredite wissen solltest</h2>
                <p>
                    Es ist üblich, dass eine Bank einen Kredit, den sie vergibt, absichert. So bleibt sie im Falle eines
                    Zahlungsausfalls nicht auf der Summe sitzen. Sichert eine Bank ein Darlehen mit Grund- oder
                    Pfandrechten an Immobilien, oder einer{" "}
                    <Link to="/artikel/hypothekendarlehen/" target="_blank" rel="noreferrer noopener">
                        Hypothek
                    </Link>{" "}
                    ab, nennt man einen Kredit Realkredit. Der wird so genannt, weil die Grundschuld dabei an einem
                    dinglichen, also realen Wert abgesichert wird. Das sind normalerweise bereits abbezahlte Immobilien,
                    aber manchmal gibt es auch andere Optionen, wie zum Beispiel Rechte an Schiffen oder ähnlichem. Die
                    Sicherheit bei Realkrediten besteht also meistens aus Vermögenswerten, wie Grundstücken oder
                    Immobilien. Man erhält bei Realkrediten ein langfristiges Darlehen mit einer Kreditsumme für den
                    Kauf oder Bau einer Immobilie. Dafür erhebt die Bank Pfandrechte oder eine Hypothek auf das
                    Grundstück oder deine Immobilie. Solltest du deine Kreditraten nicht mehr zahlen können, ist die
                    Bank dadurch immer abgesichert.
                </p>
                <p>
                    Normalerweise vergeben Banken Kredite auch nur, wenn die Bonität des Kreditnehmers oder der
                    Kreditnehmerin ausreicht. Aber diese Absicherung genügt bei hohen Immobilienkrediten nicht. Und man
                    kann nie wissen, was in Zukunft passieren wird und ob jemand immer zahlen wird können. Deswegen
                    braucht die Bank im Falle einer Zahlungsunfähigkeit eine Absicherung in einem ausreichend hohem
                    Wert, damit sie nicht auf dem Kredit sitzen bleibt.
                </p>
                <hr />

                <h2>Wie bekomme ich einen Realkredit?</h2>
                <p>
                    Das wichtigste, um einen Kredit zu bekommen, ist eine ausreichende Bonität. Durch die{" "}
                    <Link to="/artikel/bonitaet/" target="_blank" rel="noreferrer noopener">
                        Bonitätsprüfung
                    </Link>{" "}
                    stellt die Bank sicher, dass du dir die monatliche Rate wirklich leisten kannst. Dafür ist vor allem
                    ein sicheres regelmäßiges EInkommen notwendig. Zu dem Thema haben wir bereits einen eigenen Artikel
                    verfasst. Grundsätzlich gilt: Je weniger Geld du dir von der Bank ausleihen musst, desto eher
                    bekommst du einen günstigen Kredit. Es wird heute auch immer wichtiger, ausreichend Eigenkapital für
                    den Immobilienkauf mitzubringen. Dieses sollte mindestens 20% des Kaufpreises betragen.
                </p>
                <p>
                    Doch die Bonitätsprüfung und vorhandenes{" "}
                    <Link to="/artikel/immobilienfinanzierung/" target="_blank" rel="noreferrer noopener">
                        Eigenkapital
                    </Link>{" "}
                    reichen der Bank nicht aus, sondern sie verlangt bei einem Realkredit weitere Sicherheiten, wie eine
                    Hypothek oder die Eintragung einer Grundschuld ins Grundbuch. In aller Kürze erklären wir hier die
                    Unterschiede:
                </p>
                <p>
                    Bei einem Kredit mit eingetragener Grundschuld ermöglicht der Bank bei ausbleibenden Zahlungen
                    direkt eine Zwangsvollstreckung durchzuführen. Doch keine Angst, das geschieht nicht beim ersten
                    Zahlungsausfall. Wenn du merkst, dass du Schwierigkeiten mit der Rückzahlung deiner monatlichen Rate
                    hast, solltest du mit der Bank ins Gespräch treten. Gemeinsam können andere Lösungen erarbeitet
                    werden.
                </p>
                <p>
                    Eine Hypothek hängt im Gegensatz zur Grundschuld direkt mit der aktuellen Kreditsumme zusammen. Je
                    mehr du bereits abbezahlt hast, desto kleiner wird die Hypothek. Wenn dein Kredit abbezahlt ist,
                    wird sie aufgelöst. Hier musst du keinen Antrag auf Löschung stellen. Für einen neuen Kredit muss
                    deshalb auch eine neue Hypothek aufgenommen werden, was mit erneuten Kosten für die Eintragung
                    verbunden ist.
                </p>
                <p>
                    Bei einem Realkredit kannst du dich also entscheiden, welche Form von Absicherung du wählst. Beide
                    Formen haben ihre eignen Vor- und Nachteile. Während die Grundschuld für weitere Finanzierungen
                    genutzt werden kann, muss eine Hypothek immer wieder neu aufgenommen werden. Allerdings löst sich
                    die Hypothek nach der Tilgung auf, was bei einer Abschlussfinanzierung deutlich mehr Sinn ergibt, da
                    dir so unnötige Kosten und bürokratischer Aufwand erspart bleiben können.
                </p>
                <hr />

                <h2>Das wichtigste bei jedem Kredit</h2>
                <p>
                    Was du dir also merken solltest: Um einen Kredit zu bekommen, musst du über eien gute Bonität,
                    sprich sicheres und regelmäßiges Einkommen, verfügen, im besten Fall bereits ein kleines
                    Eigenkapital gespart haben und dich mit der Bank auf eine Art von Sicherheit einigen können. Ob das
                    nun ein Grundrecht oder eine Hypothek ist, musst du selbst für deine indivudelle SItuation
                    entscheiden.
                </p>
                <p>
                    Wie bei jedem Kredit gilt auch beim Realkredit: unbedingt vorher vergleichen! Einen Kredit sollte
                    man niemals auf die leichte Schulter nehmen. Deshalb haben wir ein Tool entwickelt, mit dem du ganz
                    einfach von zuhause aus Kredite miteinander vergleichen kannst und die besten Konditionen für dich
                    herausfindest. Mit einem Klick kommst du zu unserem{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                        Kreditrechner
                    </Link>
                    .
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
            </Article>

            <BreadcrumbList page={"realkredit"}></BreadcrumbList>
            <ArticleStructuredData page={"realkredit"} heading={"Was ist eigentlich ein Realkredit?"} />
        </Layout>
    );
};

export default RealKredit;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.realkredit", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
